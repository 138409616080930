import axios from "@/utils/request";

// 查询金币配置
export function queryGoldCfg(data) {
    return axios.post(`/rchg/buygoldcfg`, data);
}

// 查询支付模式
export function queryPayMode(data) {
    return axios.post(`/rchg/paymode`, data);
}

// 提交订单
export function rehgSubmit(data) {
    return axios.post(`/rchg/submit`, data);
}

// app交易记录
export function queryAppTransDetails(data) {
    return axios.post(`/trans/coinlog`, data);
}

// app交易记录
export function queryGameTransDetails(data) {
    return axios.post(`/game/wali/translog`, data);
}