<template>
  <van-popup position="bottom" v-model="isShowRechgBox" @click-overlay="$emit('close')" :round="true"
             :close-on-click-overlay="false">
    <div class="rechg_box">
      <h2 class="title">游戏币充值</h2>
      <div class="money">¥ {{ money }}</div>
      <div v-for="(item, index) in payModes" :key="index">
        <div class="rechargeList" v-if="item.payModeId != 5 && item.payModeId != 0" @click="toPay(item.payModeId)">
          <svg-icon :iconClass="handlePayType(item.payModeId, true)" class="payLogo" />
          <div class="type">{{item.payModeName}}</div>
          <svg-icon iconClass="rightArrow" />
        </div>
        <div v-if="item == 5 && dcInfo.traders && dcInfo.traders.length > 0">
          <div class="dcList" v-for="(x, y) in dcInfo.traders[0].payInfos" :key="y" @click="dcToPay(x)">
            <svg-icon :iconClass="handlePayType(x.payMethod, true)" class="payLogo" />
            <div class="titleBox">
              <div>官方代充</div>
              <div class="typeBox">
                <div>{{ handlePayType(x.payMethod) }}</div>
                <svg-icon iconClass="tuijie_icon" class="recommend"></svg-icon>
              </div>
            </div>
            <svg-icon iconClass="rightArrow" />
          </div>
        </div>
      </div>
      <div class="rechargeList" @click="toPay(0)" v-if="title=='购买会员卡'">
        <svg-icon iconClass="gold" class="payLogo" />
        <div class="type">金币兑换</div>
        <svg-icon iconClass="rightArrow" />
      </div>
    </div>
    <p class="pb40"></p>
  </van-popup>
</template>

<script>
import { rehgSubmit } from "@/api/mine/wallet";

export default {
  props: ["isShowRechgBox", "rechgPopData"],
  name: "gameRechg",
  computed: {
    title() {
      return this.rechgPopData?.title;
    },
    money() {
      return this.rechgPopData?.money / 100 ;
    },
    payModes() {
      return this.rechgPopData?.payModes;
    },
    dcInfo() {
      return this.rechgPopData?.dcInfo;
    }
  },
  methods: {
    // 提交订单
    async toPay(payType) {
      let req = {
        payAmount:  this.money,
        payMode: payType,
        productType: 1, // 0-站群 1-棋牌
        rchgUse: 4, // 0-金币 1-vip 2-观影券 3-vip升级卡 4-瓦力棋牌 5-裸聊果币
        useId: this.rechgPopData.useId
      };
      this.$store.dispatch("app/GET_LOADING", true);
      let ret = await this.$Api(rehgSubmit, req);
      if (ret && ret.code == 200) {
        window.location = ret.data.payUrl;
      } else {
        this.$toast(ret.tip || "获取支付方式失败");
      }
      this.$store.dispatch("app/GET_LOADING", false);
    },
    // 代充支付
    dcToPay(dcPayInfo) {
      let payMoney =  this.money;
      let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
      let payInfoModel = JSON.parse(JSON.stringify(dcPayInfo)); // 代充支付类型
      let token = localStorage.getItem('Authorization');
      let productInfo = {
        id: this.rechgPopData.useId,
        type: 0,
      }
      this.$store.dispatch("app/GET_LOADING", true);
      //设置默认值
      if (dcData.limit == 0) {
        dcData.limit = 500;
      }
      if (payInfoModel.payType.includes(2) && payInfoModel.payType.includes(3)) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [3];
        } else {
          payInfoModel.payType = [2];
        }
      }
      if (payInfoModel.payType.length >= 3) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [1, 3];
        } else {
          payInfoModel.payType = [1, 2];
        }
      }
      dcData.traders[0].payInfos = [payInfoModel];
      dcData.productInfo = productInfo;
      dcData.chargeMoney = payMoney;
      dcData.channel = this.rechgPopData.dcH5Domin;
      let models = JSON.stringify(dcData);
      let payUrl = `${this.rechgPopData.dcH5Domin}/?data=${this.encodeBase64(models)}&token=${token}`;
      this.$store.dispatch("app/GET_LOADING", false);
      window.location = payUrl;
    },

    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    // 支付类型
    handlePayType(payType, isSvg) {
      let nameStr, svgStr;
      switch (payType) {
        case 1:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
        case 2:
          nameStr = "微信";
          svgStr = "icon_pay_w1";
          break;
        case 3:
          nameStr = "银行卡";
          svgStr = "pay_kjyl";
          break;
        case 4:
          nameStr = "USDT";
          svgStr = "USDT";
          break;
        case 6:
          nameStr = "快捷银联";
          svgStr = "pay_kjyl";
          break;
        case 7:
          nameStr = "QQ支付";
          svgStr = "qqPay";
          break;
        case 101:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
        case 102:
          nameStr = "微信";
          svgStr = "icon_pay_w1";
          break;
        case 103:
          nameStr = "银行卡";
          svgStr = "pay_kjyl";
          break;
        case 104:
          nameStr = "信用卡";
          svgStr = "pay_kjyl";
          break;
        case 105:
          nameStr = "花呗";
          svgStr = "icon_pay_a1";
          break;
        case 106:
          nameStr = "云闪付";
          svgStr = "pay_kjyl";
          break;
        case 107:
          nameStr = "QQ钱包";
          svgStr = "pay_kjyl";
          break;
        case 108:
          nameStr = "京东支付";
          svgStr = "pay_kjyl";
          break;
        default:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
      }
      return isSvg ? svgStr : nameStr;
    }
  }
};
</script>

<style scoped lang="scss">
.pb40 {
  padding-bottom: 40px;
}

.rechg_box {
  .title {
    font-size: 20px;
    color: #000000;
    letter-spacing: 1.23px;
    text-align: center;
    font-weight: 600;
    padding: 25.5px 0;
  }

  .money {
    font-size: 36px;
    color: #FF0000;
    text-align: center;
    font-weight: 400;
  }

  .dcList {
    height: 50px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(139, 139, 139, 0.3);

    .payLogo {
      width: 34px;
      height: 34px;
    }

    .titleBox {
      flex: 2;
      margin-left: 20px;
      height: 34px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .typeBox {
        font-size: 10px;
        display: flex;
        align-items: center;

        .recommend {
          margin-left: 8px;
          width: 25px;
          height: 12.5px;
        }
      }
    }
  }

  .rechargeList {
    height: 50px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(139, 139, 139, 0.3);

    .payLogo {
      width: 34px;
      height: 34px;
    }

    .type {
      flex: 2;
      margin-left: 20px;
      font-size: 14px;
    }
  }

}
</style>
