<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">游戏钱包</div>
        <router-link tag="div" to="/problem" class="right">常见问题</router-link>
      </div>
      <div class="top">
        <div class="content">
          <div class="top-content flex-between">
            <div class="wallet-left flex-column">
              <div class="words">钱包余额（元）</div>
              <div class="num"><span class="jb"></span>{{ banlance }}</div>
            </div>
            <div class="wallet-right flex-column">
              <div class="money"></div>
              <div class="kefu flex-column" @click="server">
                <div class="kefu-icon"></div>
                <div class="kefu-words">客服</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title"><span class="tip"></span>购买金币</div>
        <div class="list flex">
          <div class="item flex-column" v-for="(item,index) in dataList" :key="item.id"
               :class="{free:item.desc, 'choose-item': selectIndex == index}" @click="choose(index)">
            <div class="jb-top"><span></span>{{ item.goldBase }}</div>
            <div class="jb-bottom">{{ item.goldBase }}元</div>
            <div class="jb-free" v-show="item.desc">{{ item.desc }}</div>
          </div>
        </div>
        <div class="paying_btn" @click="clickPaying">立即充值</div>
        <div class="pay-tip">支付小贴士</div>
        <div class="tip-w">
          1、为防止金币丢失，请绑定手机号或保存账号凭证<br />
          2、跳转后请及时付款，超时支付无法到账，要重新发起<br />
          3、如多次支付失败，请尝试其他支付方式或稍后再试<br />
          4、支付成功后一般10分钟内到账，超过30分钟未到账请带详细支付截图联系客服<br />
          5、部分安卓手机支付时误报病毒，请选择忽略即可<br />
          6、更多问题请点击查看
          <router-link tag="span" to="/problem" class="red">「常见问题」</router-link>
          <br />
        </div>
      </div>
    </div>
    <GameRechg :isShowRechgBox="isShowRechgBox" :rechgPopData="rechgPopData" @close="closeRechg" />
  </div>
</template>
<script>
import { getBanlance, getMoneyList } from "@/api/app.js";
import { getCustom } from "@/api/index.js";
import { queryPayMode } from "@/api/mine/wallet.js";
import GameRechg from "@/views/game/components/gameRechg";

export default {
  data() {
    return {
      isShowRechgBox: false,
      rechgPopData: {},
      banlance: 0, //钱包余额
      dataList: [], //充值金额列表
      selectIndex: 0,
      payModes: [],
      dcInfo: {},
      dcChannel: ""
    };
  },
  components: {
    GameRechg
  },
  created() {
    this.getBanlance();
    this.getMoneyList();
  },
  methods: {
    closeRechg() {
      this.isShowRechgBox = false;
    },
    clickPaying() {
      this.queryPayModes();
    },
    choose(index) {
      this.selectIndex = index;
    },
    async getMoneyList() { //充值金额列表
      this.$store.dispatch("app/GET_LOADING", true);
      try {
        let ret = await this.$Api(getMoneyList, {
          productType: 1
        });
        if (ret.code == 200) {
          let list = ret.data.cfgs ? ret.data.cfgs : [];
          this.dataList = list;
        }
        this.$store.dispatch("app/GET_LOADING", false);
      } catch (e) {
        this.$store.dispatch("app/GET_LOADING", false);
      }
    },
    async getBanlance() { //获取钱包余额
      let ret = await this.$Api(getBanlance, undefined);
      if (ret.code == 200) {
        this.banlance = ret.data.wlBalance;
      }
    },

    async queryPayModes() {
      try {
        //先清空数据，避免影响
        this.rechgPopData = {};
        let req = {
          payAmount: this.dataList[this.selectIndex].payAmount / 100,
          productType: 1 //0 站群 1 棋牌
        };
        this.$store.dispatch("app/GET_LOADING", true);
        let ret = await this.$Api(queryPayMode, req);
        this.$store.dispatch("app/GET_LOADING", false);
        if (ret && ret.code == 200) {
          let arr = ret.data.payModesInfo ? ret.data.payModesInfo : [];
          this.payModes = arr.filter(i => {
            return i.payModeId != 0;
          });
          this.dcInfo = ret.data.daiChong;
          this.dcChannel = ret.data.dcH5Domin;
          this.rechgPopData = {
            payModes: this.payModes,
            dcInfo: this.dcInfo,
            dcH5Domin: this.dcChannel,
            money: this.dataList[this.selectIndex].payAmount,
            useId: this.dataList[this.selectIndex].id
          };
          if (this.payModes.length == 0) {
            this.$toast("当前金额无法充值");
            return;
          }
          this.isShowRechgBox = true;
          return;
        }
        this.$toast(ret.tip || "充值失败");
      } catch (e) {
        this.$store.dispatch("app/GET_LOADING", false);
        this.$toast("充值失败");
      }
    },

    // 支付类型
    handlePayType(payType, isSvg) {
      let nameStr, svgStr;
      switch (payType) {
        case 1:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
        case 2:
          nameStr = "微信";
          svgStr = "icon_pay_w1";
          break;
        case 3:
          nameStr = "银行卡";
          svgStr = "pay_kjyl";
          break;
        case 4:
          nameStr = "USDT";
          svgStr = "USDT";
          break;
        case 6:
          nameStr = "快捷银联";
          svgStr = "pay_kjyl";
          break;
        case 101:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
        case 102:
          nameStr = "微信";
          svgStr = "icon_pay_w1";
          break;
        case 103:
          nameStr = "银行卡";
          svgStr = "pay_kjyl";
          break;
        case 104:
          nameStr = "信用卡";
          svgStr = "pay_kjyl";
          break;
        case 105:
          nameStr = "花呗";
          svgStr = "icon_pay_a1";
          break;
        case 106:
          nameStr = "云闪付";
          svgStr = "pay_kjyl";
          break;
        case 107:
          nameStr = "QQ钱包";
          svgStr = "pay_kjyl";
          break;
        case 108:
          nameStr = "京东支付";
          svgStr = "pay_kjyl";
          break;
        default:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
      }
      return isSvg ? svgStr : nameStr;
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    async server() {
      let toast = this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let ret = await this.$Api(getCustom);
      toast.clear();
      if (ret.code == 200) {
        let endpoint = ret.data.endpoint;
        location = this.$store.state.app.baseUrl + endpoint;
        return;
      }
      this.$toast(ret.tip || "加载客服失败");
    }
  }
};
</script>
<style lang="scss" scoped>
.search-all {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.paying_btn {
  position: fixed;
  left: 50%;
  margin-left: -150px;
  bottom: 30px;
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 600;
  background-image: linear-gradient(90deg, #FC4478 0%, #FF6538 99%, #F54404 100%);
  box-shadow: 0 6px 8px 0 rgba(248, 44, 44, 0.40);
  border-radius: 20px;
}

.search-box {
  padding: 0 16px;
  height: 52px;
  align-items: center;
  background: $vermillion;
}

.left {
  width: 22px;
  margin-right: 34px;
  height: 22px;
  background: url('../../assets/png/back_icon.png') center center no-repeat;
  background-size: 100%;
}

.center {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.right {
  width: 56px;
  color: #ffffff;
  font-size: 14px;
}

.top {
  height: 102px;
  background: $vermillion;
  position: relative;
}

.content {
  position: absolute;
  left: 16px;
  top: 0;
  right: 16px;
  height: 162px;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.2);
  background: #ffffff;
  padding: 15px 14px;
  box-sizing: border-box;
}

.top-content {
  margin-top: 4px;
}

.wallet-left {
  font-size: 14px;
  color: #1d1b1b;
  align-items: center;
  margin-left: 11px;
  justify-content: start;
}

.wallet-right {
  align-items: center;
}

.money {
  width: 24px;
  height: 24px;
  background: url('../../assets/png/wallet.png') center center no-repeat;
  background-size: 100%;
}

.num {
  font-size: 50px;
  color: #1c1a1a;
  margin-top: 30px;
}

.jb {
  display: inline-block;
  width: 36px;
  height: 36px;
  background: url('../../assets/png/money.png') center center no-repeat;
  background-size: 100%;
  margin-right: 3px;
}

.kefu {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: $vermillion;
  margin-top: 46px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.kefu-icon {
  width: 38px;
  height: 38px;
  background: url('../../assets/png/search_icon.png') center center no-repeat;
  background-size: 100%;
}

.kefu-words {
  margin-top: 2px;
}

.section {
  padding: 0 16px 30px;
  margin-top: 80px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.tip {
  display: inline-block;
  width: 3px;
  height: 24px;
  border-radius: 1px;
  margin-right: 2px;
  background: #d22424;
  vertical-align: -6px;
}

.list {
  flex-wrap: wrap;
}

.item {
  margin-top: 12px;
  width: 100px;
  height: 83px;
  border-radius: 6px;
  border: 1px solid #bfbfbf;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 14px;
}

.item:nth-child(3n) {
  margin-right: 0;
}

.jb-top {
  font-size: 18px;
  color: #d22424;
}

.jb-top > span {
  display: inline-block;
  width: 23px;
  height: 23px;
  background: url('../../assets/png/money.png') center center no-repeat;
  background-size: 100%;
  vertical-align: -4px;
  margin-right: 2px;
}

.jb-bottom {
  color: #1d1b1b;
  font-size: 18px;
  margin-top: 4px;
}

.free {
  justify-content: space-between;
}

.free > .jb-top {
  margin-top: 6px;
}

.jb-free {
  width: 100%;
  height: 24px;
  background: $vermillion;
  line-height: 24px;
  color: $white1;
  font-size: 11px;
  text-align: center;
}

.charge {
  margin-top: 10px;
  font-size: 16px;
}

.pay-item {
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
}

.pay-left > svg {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.go {
  width: 10px;
  height: 14px;
  background: url('../../assets/png/go_black.png') center center no-repeat;
  background-size: 100%;
}

.titleBox {
  flex: 2;
  // margin-left: 20px;
  height: 34px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .typeBox {
    font-size: 10px;
    display: flex;
    align-items: center;

    .recommend {
      margin-left: 8px;
      width: 25px;
      height: 12.5px;
    }
  }
}

.pay-tip {
  margin-top: 10px;
  font-size: 14px;
}

.tip-w {
  font-size: 10px;
  color: #515151;
  margin-top: 10px;
  line-height: 23px;
  padding-bottom: 200px;
}

.red {
  color: $vermillion;
}

.choose-item {
  background: rgba(242, 19, 19, 0.8);
}

.choose-item > .jb-top {
  color: #fece0a;
}

.choose-item > .jb-bottom {
  color: #ffffff;
}

.choose-item > .jb-free {
  border-top: 1px solid #bfbfbf;
}
</style>
